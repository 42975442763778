import { css } from '@emotion/css';
import React from 'react'
import { Handle, Position } from 'reactflow';

interface SpocIOProp {
}

const SpocIO: React.FC<SpocIOProp> = ({}) => {

  const spocIOContainer = css`
  width: 1200px;
  height: 500px;
  background-color: rgb(92, 60, 18);
  border: 5px solid black;
`;

  return (
    <div className={spocIOContainer}>

      <Handle type="target" position={Position.Top} id="target-top" style={{opacity:0}}/>
      <Handle type="target" position={Position.Left} id="target-left" style={{opacity:0}}/>
      <Handle type="target" position={Position.Right} id="target-right" style={{opacity:0}}/>
      <Handle type="target" position={Position.Bottom} id="target-bottom" style={{opacity:0}}/>
      <Handle type="source" position={Position.Top} id="source-top" style={{opacity:0}}/>
      <Handle type="source" position={Position.Top} id="source-top-1" style={{opacity:0, left:100}}/>
      <Handle type="source" position={Position.Left} id="source-left" style={{opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right" style={{opacity:0}}/>
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{opacity:0}}/>

      <p>SpocIO</p>
    </div>
  )
}

export default SpocIO;
