import React from 'react';
import { BaseEdge, EdgeLabelRenderer, EdgeProps, getSmoothStepPath } from 'reactflow';

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerStart,
  markerEnd,
  data,
}: EdgeProps) {
    
    const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const edgeColor = data && (data.power.telemetry === '1' || data.power.telemetry === 'High'|| data.power.telemetry === 'On' || (data.power.telemetry ? data.power.telemetry.includes('On') : false)) ? 'green' : style.stroke;

  console.log('edgeColor', edgeColor);

  const edgeStyle = {
    ...style,
    stroke: edgeColor,
  }

  let finaledge;

  if (id === 'Solar0ToBCR'){
    // const edge = `M${sourceX} ${sourceY}L ${sourceX-60},${sourceY}Q ${sourceX-65},${sourceY} ${sourceX-65},${sourceY+5}L ${sourceX-65},${targetY+195}Q ${sourceX-65},${targetY+200} ${sourceX-60},${targetY+200}L${targetX-200} ${targetY+200}Q ${targetX-195},${targetY+200} ${targetX-195},${targetY+195}L${targetX-195} ${targetY+5} Q${targetX-195},${targetY} ${targetX-190},${targetY} L${targetX} ${targetY}`;
    finaledge = edgePath;
  }
  else{
    finaledge = edgePath;
  }

  return (
    <>
      <BaseEdge path={finaledge} markerStart={markerStart} markerEnd={markerEnd} style={edgeStyle} />
      <EdgeLabelRenderer>
        <div style={{position: 'relative'}}>

        </div>
      </EdgeLabelRenderer>
    </>
  );

}
