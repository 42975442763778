import { css } from '@emotion/css';


export const fjBox = css`
width:920px;
height: 560px;

  & > div {
    width:920px;
    height: 480px;
    flex-wrap: wrap;

    & > div {
      width: 450px;

      & div:nth-of-type(2) {
        width: 150px;
      }
    }
  }
`;

// Define a type for the class keys
export type ClassKey = 'fjBox';