export type InnerDataValue = {
  telemetry: number;
  unit: string;
  limit: LimitProp;
  fname: string;
  value_color: string;
  dbData: Dbdata;
  spacecraft: string;
};

export type InnerData = {
  [key: string]: InnerDataValue;
};

export type VisibleTooltips = { [key: string]: boolean };

export interface LimitProp{
  rl: number | null;
  yl: number | null;
  yh: number | null;
  rh: number | null;
}

export interface Dbdata{
  telemetry: string;
  unit: string;
  //limit: LimitProp;
  limit: string;
  live: string;
  mne: string;
  spacecraft: string;
}

export interface TbProps {
    data: {
      name: string;
      value: InnerData;
      textbox_item_class: string;
      textbox_container_class: string;
      id: string;
      backgroundImage: string;
      power: TLM;
      power1: TLM;
      powerName: string;
      power1Name: string;
    };
}

export interface TLM {
  telemetry: string;
  unit: string;
  limit: string;
  live: string;
  mne: string;
  spacecraft: string;
}

export type TelemetryDictionary = {
  [key: string]: TelemetryData;
};

export type AppProps = {
  dbData: Field[];
  width: number;
  height: number;
  source: string;
  options: {
    text: string;                  // Corresponds to the 'addTextInput' option
    showSeriesCount: boolean;      // Corresponds to the 'addBooleanSwitch' option
    seriesCountSize: 'sm' | 'md' | 'lg';  // Corresponds to the 'addRadio' option
    X: number;           // Corresponds to the 'addNumberInput' option
    Y: number;
    Zoom: number;
  };
  // ... other props if there are any
};

export type TelemetryData = {
  limit: any; // Replace 'any' with the actual type of limit
  telemetry: number; // Replace 'any' with the actual type of cnvValue
  unit: string; // Replace 'any' with the actual type of unit
  live: string;
  mne: string;
  spacecraft: string;
};

export type Field = {
  name: string;
  type: string;
  labels?: { [key: string]: string };
  values: any[]; // Replace 'any' with a more specific type if possibl
};

export interface ProcessedData {
  [key: string]: { cnvValue: string, mne: string, name: string }[];
}

export interface DataObject {
  name: string;
  refId: string;
  length: number;
  fields: Field[];
  labels: { [key: string]: string };
}

export function lookUpName(mne: string) {
  for (const [key, value] of Object.entries(nameToMne)) {
    if (value === mne) {
      return key;
    }
  }
  return "NoName"; // Return null if the value is not found
}

export type LocationData = {
  Azimuth: number | null;
  Elevation: number | null;
};


export const nameToMne = {

  "Spoc Uptime":"cdh_spoc_uptime", /* Time the CPU has been running since boot */
  /*Spoc CPTN Board*/ 

  "Spvr Uptime":"cdh_supervisor_uptime", /* Time the CPU has been running since boot */
  "Spvr Boot Chip":"cdh_supervisor_boot_chip", /* Chip we booted from */
  "Spvr Boot Index": "CDH_SUPERVISOR_BOOT_INDEX", /* */
  "Spvr Node Version": "CDH_NODES_SUPERVISOR_VERSION", /* */
  "Spvr Node Build Num": "CDH_NODES_SUPERVISOR_BUILD_NUM", /* */
  "Spvr Node Build Mission": "CDH_NODES_SUPERVISOR_MISSION", /* */

  "Spvr Last Boot Source":"cdh_supervisor_pa_3_last_boot_source", /*  */
  "Spvr Next Boot Source":"cdh_supervisor_pa_3_next_boot_source", /*  */

  "Inhibit Swap Radio A":"cdh_firecode_supervisor_radio_a_ccd_2_swap_inhibit_agg", /*  */
  "Inhibit Swap Radio B":"cdh_firecode_supervisor_radio_b_ccd_2_swap_inhibit_agg", /*  */

  "Plim CPU usage":"cdh_plim_cpu_usage", /*  */

  "Cptn CSAS PPS":"cdh_spoc_cptn_ps_csac_pps_in_sel", /* ps_csac_pps_in_sel of type INT8 */

  "Flash Locked":"cdh_plim_samba_b_flash_locked", /* Flash has been locked */
  "Flash Verified":"cdh_plim_samba_b_flash_verified", /* Image has been read back from flash and veri*/

  "Plim Uptime":"cdh_plim_uptime", /*  */
  "Plim Boot Index":"cdh_plim_boot_index_agg", /*  */
  "Plim Boot Chip":"cdh_plim_boot_chip_agg", /*  */
  "Plim Boot Count":"cdh_plim_boot_count", /*  */
  "Plim Reset Reason":"cdh_plim_reset_reason", /*  */
  "Plim Cpu Temp":"cdh_plim_cpu_t", /*  */
  "Plim Cpu Usage":"cdh_plim_cpu_usage", /*  */
  "Plim Cpu Usage Peak":"cdh_plim_cpu_usage_peak", /*  */

  "PIU Uptime":"cdh_piu_uptime", /*  */
  "PIU Boot Index":"cdh_piu_boot_index_piu_agg", /*  */
  "PIU Boot Chip":"cdh_piu_boot_chip_piu_agg", /*  */
  "PIU Boot Count":"cdh_piu_boot_count_piu_agg", /*  */
  "PIU Reset Reason":"cdh_piu_reset_reason", /*  */
  "PIU Cpu Temp":"cdh_piu_cpu_t", /*  */
  "PIU Cpu Usage":"cdh_piu_cpu_usage", /*  */
  "PIU Cpu Usage Peak":"cdh_piu_cpu_usage_peak", /*  */

  "Time CSAC TOD":"CDH_TIME_CSAC_TOD", /* */
  "MAX FSW Uptime":"cdh_version_uptime_agg", /* */
  "MAX FSW App":"cdh_version_application", /* */
  "MAX FSW App Start CT": "cdh_version_application_start_count", /* */
  "MAX FSW Mission":"cdh_version_mission", /* */
  "MAX FSW Deployment":"cdh_version_deployment", /* */
  "MAX FSW Build":"cdh_version_build", /* */
  "FSW Build ID":"cdh_version_fsw_build_id", /* */

  "Q7 Uptime":"cdh_version_fc_uptime", /* */
  "Q7 Boot Cnt": "cdh_version_fc_boot_count", /* */
  "Q7 Boot Cnt Time": "cdh_version_fc_boot_count_time", /* */

  "Q7 Pa3 Boot chip": "cdh_q7_pa3_boot_chip", /* */
  "Q7 Pa3 Boot Partition":"CDH_Q7_PA3_BOOT_PARTITION", /*  */

  "Version Build": "CDH_VERSION_BUILD", /* */
  "Ops Conf Build": "CDH_VERSION_OPS_CONFIG_BUILD", /* */
  "Ops Conf Build ID": "CDH_VERSION_OPS_CONFIG_BUILD_ID", /* */
  
  "SPOC Boot Cnt": "CDH_SPOC_BOOT_COUNT", /* Current being drawn from the SPOC */
  "spoc monitor tripped": "CDH_SPOC_MONITOR_IS_TRIPPED", /* The amplitude of power int eh carrier tracking. */

  "Supvr Uptime": "cdh_supervisor_uptime", /* Time CPU running since boot */
  "Supvr Boot Ctn": "cdh_supervisor_boot_count", /* number of times processor has been booted */
  "Supvr CountDown Time": "CDH_GND_QUIET_WD_SUPERVISOR_COUNTDOWN_TIME", /* number of times processor has been booted */
  "Supvr Cool Down time": "cdh_firecode_supervisor_cool_down_time", /* Minimum time between CCD pulses */
  
  "SPOC A Voltage":"eps_supervisor_power_rail_spoca_vtlm" , /* */
  "SPOC B Voltage":"eps_supervisor_power_rail_spocb_vtlm" , /* */

  "DiskUsage MMC0 %":"cdh_diskusage_mmc0_used_percent", /* */
  "DiskUsage MMC1 %":"cdh_diskusage_mmc1_used_percent", /* */
  "DiskUsage Spinand0 %":"CDH_DISKUSAGE_SPINAND0_USED_PERCENT", /* */

  "Spoc Beacon Mode":"cdh_spoc_control_beacon_mode_flag_agg", /*  */
  "Spoc Swap Inhibbit":"cdh_spoc_control_spoc_swap_inhibit_flag_agg", /*  */
  "Spoc SwitchOver Reason":"cdh_spoc_control_switchover_reason_agg", /*  */
  "Spoc Monitor State":"cdh_spoc_monitor_state_agg", /*  */

  "RCS Tracker Disable Counter":"cdh_rcs_tracker_supervisor_disable_counter_agg", /*  */
  "RCS Tracker State":"cdh_rcs_tracker_supervisor_state_agg", /*  */
  "RCS Threshold A Enabled":"cdh_threshold_channel_rcs_a_enabled_sh", /*  */
  "RCS Threshold A Tripped":"cdh_threshold_channel_rcs_a_tripped_sh", /*  */
  "RCS Threshold B Enabled":"cdh_threshold_channel_rcs_b_enabled_sh", /*  */
  "RCS Threshold B Tripped":"cdh_threshold_channel_rcs_b_tripped_sh", /*  */

  "Plim Bossa Read Fail Count":"cdh_plim_bossa_serial_i_failcountr", /*  */
  "Plim Bossa Write Fail Count":"cdh_plim_bossa_serial_i_failcountw", /*  */
  "Plim Bossa Read Succ Count":"cdh_plim_bossa_serial_i_successcountr", /*  */
  "Plim Bossa Write Succ Count":"cdh_plim_bossa_serial_i_successcountw", /*  */
  "Plim Samba B Flash Erased":"cdh_plim_samba_b_flash_erased", /*  */
  "Plim Samba B Flash Locked":"cdh_plim_samba_b_flash_locked", /*  */
  "Plim Samba B Flash Verified":"cdh_plim_samba_b_flash_verified", /*  */
  "Plim Samba B Flash Written":"cdh_plim_samba_b_flash_written", /*  */
  "Plim Samba B Running":"cdh_plim_samba_b_isrunning", /*  */
  "Plim Samba I Byte Err":"cdh_plim_samba_i_byteerrors", /*  */


  /* Downloads */
  "File Name":"cdh_fdm_fdl_fname", /*  */
  "File Download ID":"cdh_fdm_fdl_id", /*  */
  "Num Bytes":"cdh_fdm_fdl_numbytes", /*  */
  "Num Packets":"cdh_fdm_fdl_numpckts", /*  */
  "Expected CRC":"cdh_fdm_fdl_excrc", /*  */
  "Last Seq # Sent":"cdh_fdm_fdl_lseqnum", /*  */
  "Files Count":"cdh_fdm_fdl_flcnt", /*  */
  "Byte Count":"cdh_fdm_fdl_bycnt", /*  */

  /* Uploads */
  "File Name Upload":"fum_ful_fname", /*  */
  "File Upload ID":"fum_ful_id", /*  */
  "Num Bytes Upload":"fum_ful_numbytes", /*  */
  "Num Packets Upload":"fum_ful_numpckts", /*  */
  "Pkt Remaining":"fum_ful_totmis", /*  */
  "Expected CRC Upload":"fum_ful_excrc", /*  */
  "Calculated CRC":"fum_ful_cacrc", /*  */
  "Rej Cmd":"fum_ful_rejcmdcnt", /*  */
  "Rej Files":"fum_ful_rejflcnt", /*  */
  "Accepted Files":"fum_ful_acpflcnt", /*  */
  "Accepted Bytes":"fum_ful_acpbycnt", /*  */

  "FJ Eng 00 Status":"fj_status_00_agg", /*  */
  "FJ Eng 01 Status":"fj_status_01_agg", /*  */
  "FJ Eng 02 Status":"fj_status_02_agg", /*  */
  "FJ Eng 03 Status":"fj_status_03_agg", /*  */
  "FJ Eng 04 Status":"fj_status_04_agg", /*  */
  "FJ Eng 05 Status":"fj_status_05_agg", /*  */
  "FJ Eng 06 Status":"fj_status_06_agg", /*  */
  "FJ Eng 07 Status":"fj_status_07_agg", /*  */
  "FJ Eng 08 Status":"fj_status_08_agg", /*  */
  "FJ Eng 09 Status":"fj_status_09_agg", /*  */
  "FJ Eng 10 Status":"fj_status_10_agg", /*  */
  "FJ Eng 11 Status":"fj_status_11_agg", /*  */
  "FJ Eng 12 Status":"fj_status_12_agg", /*  */
  "FJ Eng 13 Status":"fj_status_13_agg", /*  */
  "FJ Eng 14 Status":"fj_status_14_agg", /*  */
  "FJ Eng 15 Status":"fj_status_15_agg", /*  */

  "Vehicle Mode Agg":"state_spinand0_last_cmd_vehicle_mode_agg",
  "Vehicle Mode Sh":"state_spinand0_last_cmd_vehicle_mode_sh", /* */
}

export function determineColor(value: number, limit: LimitProp){
  if (limit.rl !== null && value < limit.rl){
    return 'red-limit';
  }else if (limit.rh !== null && value > limit.rh){
    return 'red-limit';
  }else if (limit.rl !== null && limit.yl !== null && value >= limit.rl && value < limit.yl) {
    return 'yellow-limit';
  }else if (limit.yh !== null && limit.rh !== null && value >= limit.yh && value < limit.rh) {
    return 'yellow-limit';
  }else if (limit.yl !== null && limit.yh !== null && value >= limit.yl && value < limit.yh) {
    return 'green-limit';
  }else if (limit.rl !== null && limit.rh !== null && value >= limit.rl && value < limit.rh){
    return 'green-limit';
  }else{
    return 'gray-limit';
  }
}

export function determineColorNew(name: string){
if (name === 'rl' || name === 'rh' || name === 'RH' || name === 'RL'){
  return 'red-limit';
}
else if (name === 'yl' || name === 'yh' || name === 'YH' || name === 'YL'){
    return 'yellow-limit';
}else{
  return 'gray-limit';
}
}

export const checkOutOfLimits = (data: InnerData) => {
  Object.entries(data).forEach(([key, innerData]) => {
    if (innerData && innerData.dbData && innerData.dbData.limit) {
      const limitClass = determineColorNew(innerData.dbData.limit);
      if (limitClass === 'red-limit') {
        return `rgb(246, 80, 80)`;
      }
      else if (limitClass === 'yellow-limit') {
        return `rgb(230, 230, 65)`; 
      }
    }
  });
  return `green`;
};

export const checkOutOfLimitsEx = (data: InnerData) => {
  let sawRed = false, sawYellow = false, sawLive = false, sawWithin12 = false;
  Object.entries(data).forEach(([key, innerData]) => {
    if (innerData && innerData.dbData && innerData.dbData?.limit) {
      const limitClass = determineColorNew(innerData.dbData?.limit);
      if (limitClass === 'red-limit') {
        sawRed = true;
      }
      else if (limitClass === 'yellow-limit') {
        sawYellow = true; 
      }
    }
    else if (innerData.dbData?.live === 'rgb(72, 200, 44)') {
      sawLive = true;
    }
    else if (innerData.dbData?.live === '#CA51EC') {
      sawWithin12 = true;
    }
  });
  if (sawRed) {
    return `rgb(246, 80, 80)`;
  }
  else if (sawYellow) {
    return `rgb(230, 230, 65)`;
  }
  else if (sawLive) {
    return `rgb(72, 200, 44)`;
  }
  else if (sawWithin12) {
    return `#CA51EC`;
  }else {
    return `black`;
  }
};

export const checkOutOfLimitsHid = (data: InnerData) => {
  //console.log(data);
  let sawRed = false, sawYellow = false, sawLive = false, sawWithin12 = false;
  Object.entries(data).forEach(([key, innerData]) => {
    if (innerData && innerData.dbData && innerData.dbData?.limit) {
      const limitClass = determineColorNew(innerData.dbData?.limit);
      if (limitClass === 'red-limit') {
        sawRed = true;
      }
      else if (limitClass === 'yellow-limit') {
        sawYellow = true; 
      }
    }
    else if (innerData.dbData?.live === 'rgb(72, 200, 44)') {
      sawLive = true;
    }
    else if (innerData.dbData?.live === '#CA51EC') {
      sawWithin12 = true;
    }
  });
  if (sawRed) {
    return `rgb(246, 80, 80)`;
  }
  else if (sawYellow) {
    return `rgb(230, 230, 65)`;
  }
  else if (sawLive) {
    return `rgb(72, 200, 44)`;
  }
  else if (sawWithin12) {
    return `#CA51EC`;
  }
  else {
    return `gray`;
  }
};
