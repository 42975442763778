import React, {useCallback, useEffect, useRef, useState } from 'react';
import ReactFlow, { ReactFlowInstance, ReactFlowProvider} from 'reactflow';
import 'reactflow/dist/style.css';
import { ProcessedData, nameToMne } from './utils';
import CustomEdge from './CustomEdge';
import { css } from '@emotion/css';
import SpocIO from 'module/SpocIO';
import SpocCPTN from 'module/SpocCPTN';
import Spoc from 'module/Spoc';
import ExxBox from './ExxBox';
import Panel from 'module/Panel';
import MemoryBox from './MemoryBox';
import VModeBox from './VModeBox';
import { useTimeRangeContext } from './TimeRangeContext';

type TelemetryDictionary = {
  [key: string]: TelemetryData;
};

type TelemetryData = {
  limit: any; // Replace 'any' with the actual type of limit asd
  telemetry: string; // Replace 'any' with the actual type of cnvValue
  unit: string; // Replace 'any' with the actual type f unit
  live: string;
  mne: string;
  spacecraft: string;
};

type Field = {
  name: string;
  type: string;
  values: any[]; // Replace 'any' with a more specific type if possible
};

type AppProps = {
  dbData: Field[];
  width: number;
  height: number;
  source: string;
  options: {
    text: string;                  // Corresponds to the 'addTextInput' option
    showSeriesCount: boolean;      // Corresponds to the 'addBooleanSwitch' option
    seriesCountSize: 'sm' | 'md' | 'lg';  // Corresponds to the 'addRadio' option
    X: number;           // Corresponds to the 'addNumberInput' option
    Y: number;
    Zoom: number;
  };
  influxData: ProcessedData;

  // ... other props if there are any
};

const nodeTypes = {panel:Panel,exxbox:ExxBox,memoryBox:MemoryBox,vmodeBox:VModeBox, spocIo:SpocIO,spocCptn:SpocCPTN,spoc:Spoc,}
const edgeTypes = {customEdge: CustomEdge}

const reactAppWrapper = css`

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.react-flow__node {
  z-index: 10 !important;
}

.react-flow__edges {
  z-index: 9 !important;
}

.react-flow__node-panel, .react-flow__node-rcsPanel, .react-flow__node-spoc{
  z-index: 5 !important;
}

.react-flow__node-solarCell{
  z-index: 6 !important;

}

.react-flow__edge-span{
  z-index: 3 !important;
}

  position: relative;
  background-color: #161616;
  color: #FFFFFF;
  font-size: 32px;
  font-family: monospace;
  box-sizing: border-box;
  text-align: center;
  display: flex;
`;

const App: React.FC<AppProps> = ({dbData, source, width, height, options, influxData}) => {

  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance | null>(null);
  const { guiValues } = useTimeRangeContext();
  // Use refs to always have the current values
  const pbTimeRef = useRef("0");
  const pbLoopRef = useRef(0);

  const onInit = useCallback((instance: ReactFlowInstance) => {
    setReactFlowInstance(instance);
  }, []);

  useEffect(() => {
    if (reactFlowInstance ) {
      reactFlowInstance.fitView();
    }
  }
  ,[width, height, reactFlowInstance]);


  function isWithinDifference(unixTimestamp: number) {

    // Convert the Unix timestamp to a JavaScript Date object
    const timestampDate = new Date(unixTimestamp * 1000);

    // Get the current time
    const currentDate = new Date();

    // Calculate the difference in seconds
    const timeDifference = (currentDate.getTime() - timestampDate.getTime()) / 1000;

    // Compare and return
    if (timeDifference <= 30) {
        return "rgb(72, 200, 44)";
    } else if (timeDifference <= 43200) { // 43200 seconds = 12 hours 
        return "#CA51EC";
    } else {
        return "rgb(68, 169, 241)"; //blue -- stale data
    }
  }

  const getDataFromSource = useCallback((mne: string) => {
    const mneColumn = dbData.find(column => column.name === "mne");
    if (!mneColumn) {
      return { limit: null, cnvValue: null, unit: null, live: "", mne: mne  }; // mne column not found
    }
  
    const mneIndex = mneColumn.values.indexOf(mne);
    if (mneIndex === -1) {
      return { limit: null, cnvValue: null, unit: null, live: "", mne: mne  }; // mne not found
    }
  
    // Safely get the other columns
    const limitColumn = dbData.find(column => column.name === "limit");
    const cnvValueColumn = dbData.find(column => column.name === "cnvValue");
    const unitColumn = dbData.find(column => column.name === "units");
    const timeColumn = dbData.find(column => column.name === "tInsert");
  
    const limit = limitColumn ? limitColumn.values[mneIndex] : null;
    let cnvValue = cnvValueColumn ? cnvValueColumn.values[mneIndex] : null;
    let unit = unitColumn ? unitColumn.values[mneIndex] : null;
    let live = timeColumn ? isWithinDifference(timeColumn.values[mneIndex]) : "";

    // Append the degree symbol tothe unit if the unit is 'C'
    if (unit === 'C' || unit === 'degC' || unit === 'deg C' || unit === 'Celsius') {
      unit = '\u00B0C';
    }else if(unit === 'packets'){
      unit = 'P'
    }else if(unit === 'sec'){
      unit = 'S'
    }else if(unit === 'None' || unit === 'na' || unit === 'enum' || unit === 'N/A'){
      unit = null
    }else if(unit === 'bool'){
      unit = 'B'
    }else if(unit === 'count' || unit === 'Counts' || unit === 'counts'){
      unit = 'CT'
    }else if(unit === 'rad/s'){
      unit = 'R/S'
    }else if(unit === 'Percent' || unit === 'percent' || unit === 'Percentage' || unit === 'percentage'){
      unit = '%'
    }
  
// Special handling for 'WFI HV' and 'NFI HV'
if (mne === 'cdh_version_fc_boot_count_time') {
  cnvValue = parseFloat(cnvValue).toExponential(2);
} else if (cnvValue !== null && !isNaN(parseFloat(cnvValue))) {
  // Check if cnvValue is a number
  let numValue = parseFloat(cnvValue);

  // Convert to exponential notation if the integer part has more than 7 digits
  if (Math.abs(numValue) >= 10000000) {
    cnvValue = numValue.toExponential(2);
  } else if (cnvValue.includes('.')) {
    // It's a float, parse and keep 2 decimal places
    cnvValue = numValue.toFixed(2);
  }
} 
  
    return { limit, cnvValue, unit, live, mne };
  },[dbData]);

  // Function to create the new dictionary
  const createTelemetryDictionary = useCallback(() => {
    const telemetryDict: Record<string, TelemetryData> = {};
  
    // Get the vehicle mode
    const vMode = getDataFromSource('state_spinand0_last_cmd_vehicle_mode_agg').cnvValue;
  
    Object.entries(nameToMne).forEach(([name, mne]) => {
      // Choose suffix based on vMode
      let suffix = vMode === "SAFE_MODE" ? "_sh" : "_agg";
      let newMne = mne + suffix;
      let data = getDataFromSource(newMne.toLowerCase());
  
      // If the cnvValue is null or empty, try with the regular mne
      if (!data.cnvValue) {
        newMne = mne; // Use the regular mnemonic
        data = getDataFromSource(newMne.toLowerCase());
      }
  
      telemetryDict[name] = {
        limit: data.limit,
        mne: data.mne,
        telemetry: data.cnvValue,
        unit: data.unit,
        live: data.live,
        spacecraft: source ? source : ""
      };
    });
  
    return telemetryDict;
  }, [getDataFromSource, nameToMne, source]);

  const telemetryDictionary = createTelemetryDictionary();

  const [nodes, setNodes] = useState([
    { id: 'CDH', type: 'panel', position:{x:150, y:50}, data:{'source':source,width:2550, height:1550,name:"CDH", hlink:"http://ops-display.ssl.berkeley.edu/d/edpzc6xclv4zkd/escapade-cdh?orgId=1&refresh=5s&from=1721644020030&to=1721687220030"}},
    { id: 'FSW', type: 'panel', position:{x:150, y:1600}, data:{'source':source,width:2550, height:900, name:"FSW"}},

    { id: 'FJ', type: 'exxbox',parentNode:'FSW', position:{x:1550, y:50}, data:{name:'FJ',oriHeight:'550px', exWidth:'900px',className:'fjBox', value:{"Eng 00 Status":{'dbData':telemetryDictionary['FJ Eng 00 Status'],'fname':''},"Eng 01 Status":{'dbData':telemetryDictionary['FJ Eng 01 Status'],'fname':''},"Eng 02 Status":{'dbData':telemetryDictionary['FJ Eng 02 Status'],'fname':''},"Eng 03 Status":{'dbData':telemetryDictionary['FJ Eng 03 Status'],'fname':''},"Eng 04 Status":{'dbData':telemetryDictionary['FJ Eng 04 Status'],'fname':''},"Eng 05 Status":{'dbData':telemetryDictionary['FJ Eng 05 Status'],'fname':''},"Eng 06 Status":{'dbData':telemetryDictionary['FJ Eng 06 Status'],'fname':''},"Eng 07 Status":{'dbData':telemetryDictionary['FJ Eng 07 Status'],'fname':''},"Eng 08 Status":{'dbData':telemetryDictionary['FJ Eng 08 Status'],'fname':''},"Eng 09 Status":{'dbData':telemetryDictionary['FJ Eng 09 Status'],'fname':''},"Eng 10 Status":{'dbData':telemetryDictionary['FJ Eng 10 Status'],'fname':''},"Eng 11 Status":{'dbData':telemetryDictionary['FJ Eng 11 Status'],'fname':''},"Eng 12 Status":{'dbData':telemetryDictionary['FJ Eng 12 Status'],'fname':''},"Eng 13 Status":{'dbData':telemetryDictionary['FJ Eng 13 Status'],'fname':''},"Eng 14 Status":{'dbData':telemetryDictionary['FJ Eng 14 Status'],'fname':''},"Eng 15 Status":{'dbData':telemetryDictionary['FJ Eng 15 Status'],'fname':''}}}},
    { id: 'Downlaod', type: 'exxbox',parentNode:'FSW', position:{x:800, y:325}, data:{name:'Downlaod', exWidth:'560px', value:{"File Name":{'dbData':telemetryDictionary['File Name'],'fname':''},"File Download ID":{'dbData':telemetryDictionary['File Download ID'],'fname':''},"Num Bytes":{'dbData':telemetryDictionary['Num Bytes'],'fname':''},"Num Packets":{'dbData':telemetryDictionary['Num Packets'],'fname':''},"Expected CRC":{'dbData':telemetryDictionary['Expected CRC'],'fname':''},"Last Seq # Sent":{'dbData':telemetryDictionary['Last Seq # Sent'],'fname':''},"Files Count":{'dbData':telemetryDictionary['Files Count'],'fname':''},"Byte Count":{'dbData':telemetryDictionary['Byte Count'],'fname':''}}}},
    { id: 'MEM', type: 'memoryBox',parentNode:'FSW', position:{x:850, y:50}, data:{name:'Memory', value:{"MMC0":{'dbData':telemetryDictionary['DiskUsage MMC0 %'],'fname':''},"MMC1":{'dbData':telemetryDictionary['DiskUsage MMC1 %'],'fname':''},"SPINAND":{'dbData':telemetryDictionary['DiskUsage Spinand0 %'],'fname':''}}}},
    { id: 'Upload', type: 'exxbox',parentNode:'FSW', position:{x:100, y:50}, data:{name:'Upload',oriHeight:'550px',exWidth:'530px', value:{"File Name":{'dbData':telemetryDictionary['File Name Upload'],'fname':''},"File Upload ID":{'dbData':telemetryDictionary['File Upload ID'],'fname':''},"Num Bytes":{'dbData':telemetryDictionary['Num Bytes Upload'],'fname':''},"Num Packets":{'dbData':telemetryDictionary['Num Packets Upload'],'fname':''},"Pkt Remaining":{'dbData':telemetryDictionary['Pkt Remaining'],'fname':''},"Expected CRC":{'dbData':telemetryDictionary['Expected CRC Upload'],'fname':''},"Calculated CRC":{'dbData':telemetryDictionary['Calculated CRC'],'fname':''},"Rej Cmd":{'dbData':telemetryDictionary['Rej Cmd'],'fname':''},"Rej Files":{'dbData':telemetryDictionary['Rej Files'],'fname':''},"Accepted Files":{'dbData':telemetryDictionary['Accepted Files'],'fname':''},"Accepted Bytes":{'dbData':telemetryDictionary['Accepted Bytes'],'fname':''}}}},

    { id: 'Q7', type: 'exxbox',parentNode:'CDH', position:{x:50, y:550}, data:{name:'Q7',oriHeight:'575px', exWidth:'580px', value:{"FSW Build ID":{'dbData':telemetryDictionary['FSW Build ID'],'fname':''},"MAX FSW Uptime":{'dbData':telemetryDictionary['MAX FSW Uptime'],'fname':''},"MAX FSW App":{'dbData':telemetryDictionary['MAX FSW App'],'fname':''},"MAX App Start Cnt":{'dbData':telemetryDictionary['MAX FSW App Start CT'],'fname':''},"MAX FSW Mission":{'dbData':telemetryDictionary['MAX FSW Mission'],'fname':''},"MAX FSW Build":{'dbData':telemetryDictionary['MAX FSW Build'],'fname':''},"MAX FSW Deployment":{'dbData':telemetryDictionary['MAX FSW Deployment'],'fname':''},"Q7 Uptime":{'dbData':telemetryDictionary['Q7 Uptime'],'fname':''},"Q7 Boot Chip":{'dbData':telemetryDictionary['Q7 Pa3 Boot chip'],'fname':''},"Q7 Boot Cnt":{'dbData':telemetryDictionary['Q7 Boot Cnt'],'fname':''},"Q7 Boot Cnt Time":{'dbData':telemetryDictionary['Q7 Boot Cnt Time'],'fname':''},"Q7 Boot Part":{'dbData':telemetryDictionary['Q7 Pa3 Boot Partition'],'fname':''},"Version Build":{'dbData':telemetryDictionary['Version Build'],'fname':''},"OpsCfg Build":{'dbData':telemetryDictionary['Ops Conf Build'],'fname':''},"OpsCfg Build ID":{'dbData':telemetryDictionary['Ops Conf Build ID'],'fname':''}}}},
    { id: 'Spvr', type: 'exxbox',parentNode:'CDH', position:{x:1875, y:725}, data:{name:'Supervisor',oriHeight:'550px', exWidth:'600px', value:{"Uptime":{'dbData':telemetryDictionary['Supvr Uptime'],'fname':''}, "Boot Ctn":{'dbData':telemetryDictionary['Supvr Boot Ctn'],'fname':''}, "CntDown Time":{'dbData':telemetryDictionary['Supvr CountDown Time'],'fname':''},"M4 Last Boot Src":{'dbData':telemetryDictionary['Spvr Last Boot Source'],'fname':''}, "M4 Next Boot Src":{'dbData':telemetryDictionary['Spvr Next Boot Source'],'fname':''}, "Boot Chip":{'dbData':telemetryDictionary['Spvr Boot Chip'],'fname':''}, "Boot Index":{'dbData':telemetryDictionary['Spvr Boot Index'],'fname':''}, "FSW Version":{'dbData':telemetryDictionary['Spvr Node Version'],'fname':''}, "FSW Build":{'dbData':telemetryDictionary['Spvr Node Build Num'],'fname':''}, "Mission":{'dbData':telemetryDictionary['Spvr Node Build Mission'],'fname':'Node Build Mission'}, "Inhibit Swap RA":{'dbData':telemetryDictionary['Inhibit Swap Radio A'],'fname':'Inhibit Swap Radio A'}, "Inhibit Swap RB":{'dbData':telemetryDictionary['Inhibit Swap Radio B'],'fname':'Inhibit Swap Radio B'}}}},
    { id: 'Spoc', type: 'exxbox',parentNode:'CDH', position:{x:975, y:725}, data:{name:'Spoc',oriHeight:'550px', exWidth:'520px', value:{"Uptime":{'dbData':telemetryDictionary['Spoc Uptime'],'fname':''},"Time CSAC TOD":{'dbData':telemetryDictionary['Time CSAC TOD'],'fname':''},"Beacon Mode":{'dbData':telemetryDictionary['Spoc Beacon Mode'],'fname':''},"Monitor Trp":{'dbData':telemetryDictionary['spoc monitor tripped'],'fname':''},"Monitor State":{'dbData':telemetryDictionary['Spoc Monitor State'],'fname':''},"Swap Inhibit":{'dbData':telemetryDictionary['Spoc Swap Inhibbit'], 'fname':''},"Swap Reason":{'dbData':telemetryDictionary['Spoc SwitchOver Reason'], 'fname':''},"Boot Count":{'dbData':telemetryDictionary['SPOC Boot Cnt'],'fname':'SPOC Boot Cnt'}}}},
    
    { id: 'PlimFlash', type: 'exxbox',parentNode:'CDH', position:{x:1800, y:50}, data:{name:'Plim Flash', exWidth:'700px', value:{"Bossa Read Fail Count":{'dbData':telemetryDictionary['Plim Bossa Read Fail Count'],'fname':''},"Bossa Write Fail Count":{'dbData':telemetryDictionary['Plim Bossa Write Fail Count'],'fname':''},"Bossa Read Succ Count":{'dbData':telemetryDictionary['Plim Bossa Read Succ Count'],'fname':''},"Bossa Write Succ Count":{'dbData':telemetryDictionary['Plim Bossa Write Succ Count'],'fname':''},"Samba B Flash Erased":{'dbData':telemetryDictionary['Plim Samba B Flash Erased'],'fname':''},"Samba B Flash Locked":{'dbData':telemetryDictionary['Plim Samba B Flash Locked'],'fname':''},"Samba B Flash Verified":{'dbData':telemetryDictionary['Plim Samba B Flash Verified'],'fname':''},"Samba B Flash Written":{'dbData':telemetryDictionary['Plim Samba B Flash Written'],'fname':''},"Samba B Running":{'dbData':telemetryDictionary['Plim Samba B Running'],'fname':''},"Samba I Byte Err":{'dbData':telemetryDictionary['Plim Samba I Byte Err'],'fname':''}}}},
    { id: 'Plim', type: 'exxbox',parentNode:'CDH', position:{x:1225, y:50}, data:{name:'Plim', exWidth:'520px', value:{"Uptime":{'dbData':telemetryDictionary['Plim Uptime'],'fname':''},"Boot Index":{'dbData':telemetryDictionary['Plim Boot Index'],'fname':''},"Boot Count":{'dbData':telemetryDictionary['Plim Boot Count'],'fname':''},"Boot Chip":{'dbData':telemetryDictionary['Plim Boot Chip'],'fname':''},"Reset Reason":{'dbData':telemetryDictionary['Plim Reset Reason'],'fname':''},"Cpu Temp":{'dbData':telemetryDictionary['Plim Cpu Temp'],'fname':''},"Cpu Usage":{'dbData':telemetryDictionary['Plim Cpu Usage'],'fname':''},"Cpu Usage Peak":{'dbData':telemetryDictionary['Plim Cpu Usage Peak'],'fname':''}}}},
    { id: 'RCS', type: 'exxbox',parentNode:'CDH', position:{x:50, y:50}, data:{name:'RCS', exWidth:'550px', value:{"Trk Disable Cnt":{'dbData':telemetryDictionary['RCS Tracker Disable Counter'],'fname':'Tracker Disable Counter'},"Trk State":{'dbData':telemetryDictionary['RCS Tracker State'],'fname':''},"Threshold A En":{'dbData':telemetryDictionary['RCS Threshold A Enabled'],'fname':''},"Threshold A Trp":{'dbData':telemetryDictionary['RCS Threshold A Tripped'],'fname':''},"Threshold B En":{'dbData':telemetryDictionary['RCS Threshold B Enabled'],'fname':''},"Threshold B Trp":{'dbData':telemetryDictionary['RCS Threshold B Tripped'],'fname':''}}}},
    { id: 'PIU', type: 'exxbox',parentNode:'CDH', position:{x:650, y:50}, data:{name:'PIU', exWidth:'525px', value:{"Uptime":{'dbData':telemetryDictionary['PIU Uptime'],'fname':''},"Boot Index":{'dbData':telemetryDictionary['PIU Boot Index'],'fname':''},"Boot Count":{'dbData':telemetryDictionary['PIU Boot Count'],'fname':''},"Boot Chip":{'dbData':telemetryDictionary['PIU Boot Chip'],'fname':''},"Reset Reason":{'dbData':telemetryDictionary['PIU Reset Reason'],'fname':''},"Cpu Temp":{'dbData':telemetryDictionary['PIU Cpu Temp'],'fname':''},"Cpu Usage":{'dbData':telemetryDictionary['PIU Cpu Usage'],'fname':''},"Cpu Usage Peak":{'dbData':telemetryDictionary['PIU Cpu Usage Peak'],'fname':''}}}},
    
    { id: 'InfoBox', type: 'vmodeBox',parentNode:'', position:{x:150, y:-60}, data:{name:'', VMode:telemetryDictionary['Vehicle Mode Agg'], playBackValue: {time: pbTimeRef.current, loop: pbLoopRef.current}}},
]);

  const createNodes = (telemetryDictionary: TelemetryDictionary) => {
    return [
      { id: 'CDH', type: 'panel', position:{x:150, y:50}, data:{'source':source,width:2550, height:1550,name:"CDH", hlink:"http://ops-display.ssl.berkeley.edu/d/edpzc6xclv4zkd/escapade-cdh?orgId=1&refresh=5s&from=1721644020030&to=1721687220030"}},
      { id: 'FSW', type: 'panel', position:{x:150, y:1600}, data:{'source':source,width:2550, height:900, name:"FSW"}},

      { id: 'FJ', type: 'exxbox',parentNode:'FSW', position:{x:1550, y:50}, data:{name:'FJ',oriHeight:'550px', exWidth:'900px',className:'fjBox', value:{"Eng 00 Status":{'dbData':telemetryDictionary['FJ Eng 00 Status'],'fname':''},"Eng 01 Status":{'dbData':telemetryDictionary['FJ Eng 01 Status'],'fname':''},"Eng 02 Status":{'dbData':telemetryDictionary['FJ Eng 02 Status'],'fname':''},"Eng 03 Status":{'dbData':telemetryDictionary['FJ Eng 03 Status'],'fname':''},"Eng 04 Status":{'dbData':telemetryDictionary['FJ Eng 04 Status'],'fname':''},"Eng 05 Status":{'dbData':telemetryDictionary['FJ Eng 05 Status'],'fname':''},"Eng 06 Status":{'dbData':telemetryDictionary['FJ Eng 06 Status'],'fname':''},"Eng 07 Status":{'dbData':telemetryDictionary['FJ Eng 07 Status'],'fname':''},"Eng 08 Status":{'dbData':telemetryDictionary['FJ Eng 08 Status'],'fname':''},"Eng 09 Status":{'dbData':telemetryDictionary['FJ Eng 09 Status'],'fname':''},"Eng 10 Status":{'dbData':telemetryDictionary['FJ Eng 10 Status'],'fname':''},"Eng 11 Status":{'dbData':telemetryDictionary['FJ Eng 11 Status'],'fname':''},"Eng 12 Status":{'dbData':telemetryDictionary['FJ Eng 12 Status'],'fname':''},"Eng 13 Status":{'dbData':telemetryDictionary['FJ Eng 13 Status'],'fname':''},"Eng 14 Status":{'dbData':telemetryDictionary['FJ Eng 14 Status'],'fname':''},"Eng 15 Status":{'dbData':telemetryDictionary['FJ Eng 15 Status'],'fname':''}}}},
      { id: 'Downlaod', type: 'exxbox',parentNode:'FSW', position:{x:800, y:325}, data:{name:'Downlaod', exWidth:'560px', value:{"File Name":{'dbData':telemetryDictionary['File Name'],'fname':''},"File Download ID":{'dbData':telemetryDictionary['File Download ID'],'fname':''},"Num Bytes":{'dbData':telemetryDictionary['Num Bytes'],'fname':''},"Num Packets":{'dbData':telemetryDictionary['Num Packets'],'fname':''},"Expected CRC":{'dbData':telemetryDictionary['Expected CRC'],'fname':''},"Last Seq # Sent":{'dbData':telemetryDictionary['Last Seq # Sent'],'fname':''},"Files Count":{'dbData':telemetryDictionary['Files Count'],'fname':''},"Byte Count":{'dbData':telemetryDictionary['Byte Count'],'fname':''}}}},
      { id: 'MEM', type: 'memoryBox',parentNode:'FSW', position:{x:850, y:50}, data:{name:'Memory', value:{"MMC0":{'dbData':telemetryDictionary['DiskUsage MMC0 %'],'fname':''},"MMC1":{'dbData':telemetryDictionary['DiskUsage MMC1 %'],'fname':''},"SPINAND":{'dbData':telemetryDictionary['DiskUsage Spinand0 %'],'fname':''}}}},
      { id: 'Upload', type: 'exxbox',parentNode:'FSW', position:{x:100, y:50}, data:{name:'Upload',oriHeight:'550px',exWidth:'530px', value:{"File Name":{'dbData':telemetryDictionary['File Name Upload'],'fname':''},"File Upload ID":{'dbData':telemetryDictionary['File Upload ID'],'fname':''},"Num Bytes":{'dbData':telemetryDictionary['Num Bytes Upload'],'fname':''},"Num Packets":{'dbData':telemetryDictionary['Num Packets Upload'],'fname':''},"Pkt Remaining":{'dbData':telemetryDictionary['Pkt Remaining'],'fname':''},"Expected CRC":{'dbData':telemetryDictionary['Expected CRC Upload'],'fname':''},"Calculated CRC":{'dbData':telemetryDictionary['Calculated CRC'],'fname':''},"Rej Cmd":{'dbData':telemetryDictionary['Rej Cmd'],'fname':''},"Rej Files":{'dbData':telemetryDictionary['Rej Files'],'fname':''},"Accepted Files":{'dbData':telemetryDictionary['Accepted Files'],'fname':''},"Accepted Bytes":{'dbData':telemetryDictionary['Accepted Bytes'],'fname':''}}}},

      { id: 'Q7', type: 'exxbox',parentNode:'CDH', position:{x:50, y:550}, data:{name:'Q7',oriHeight:'575px', exWidth:'580px', value:{"FSW Build ID":{'dbData':telemetryDictionary['FSW Build ID'],'fname':''},"MAX FSW Uptime":{'dbData':telemetryDictionary['MAX FSW Uptime'],'fname':''},"MAX FSW App":{'dbData':telemetryDictionary['MAX FSW App'],'fname':''},"MAX App Start Cnt":{'dbData':telemetryDictionary['MAX FSW App Start CT'],'fname':''},"MAX FSW Mission":{'dbData':telemetryDictionary['MAX FSW Mission'],'fname':''},"MAX FSW Build":{'dbData':telemetryDictionary['MAX FSW Build'],'fname':''},"MAX FSW Deployment":{'dbData':telemetryDictionary['MAX FSW Deployment'],'fname':''},"Q7 Uptime":{'dbData':telemetryDictionary['Q7 Uptime'],'fname':''},"Q7 Boot Chip":{'dbData':telemetryDictionary['Q7 Pa3 Boot chip'],'fname':''},"Q7 Boot Cnt":{'dbData':telemetryDictionary['Q7 Boot Cnt'],'fname':''},"Q7 Boot Cnt Time":{'dbData':telemetryDictionary['Q7 Boot Cnt Time'],'fname':''},"Q7 Boot Part":{'dbData':telemetryDictionary['Q7 Pa3 Boot Partition'],'fname':''},"Version Build":{'dbData':telemetryDictionary['Version Build'],'fname':''},"OpsCfg Build":{'dbData':telemetryDictionary['Ops Conf Build'],'fname':''},"OpsCfg Build ID":{'dbData':telemetryDictionary['Ops Conf Build ID'],'fname':''}}}},
      { id: 'Spvr', type: 'exxbox',parentNode:'CDH', position:{x:1875, y:725}, data:{name:'Supervisor',oriHeight:'550px', exWidth:'600px', value:{"Uptime":{'dbData':telemetryDictionary['Supvr Uptime'],'fname':''}, "Boot Ctn":{'dbData':telemetryDictionary['Supvr Boot Ctn'],'fname':''}, "CntDown Time":{'dbData':telemetryDictionary['Supvr CountDown Time'],'fname':''},"M4 Last Boot Src":{'dbData':telemetryDictionary['Spvr Last Boot Source'],'fname':''}, "M4 Next Boot Src":{'dbData':telemetryDictionary['Spvr Next Boot Source'],'fname':''}, "Boot Chip":{'dbData':telemetryDictionary['Spvr Boot Chip'],'fname':''}, "Boot Index":{'dbData':telemetryDictionary['Spvr Boot Index'],'fname':''}, "FSW Version":{'dbData':telemetryDictionary['Spvr Node Version'],'fname':''}, "FSW Build":{'dbData':telemetryDictionary['Spvr Node Build Num'],'fname':''}, "Mission":{'dbData':telemetryDictionary['Spvr Node Build Mission'],'fname':'Node Build Mission'}, "Inhibit Swap RA":{'dbData':telemetryDictionary['Inhibit Swap Radio A'],'fname':'Inhibit Swap Radio A'}, "Inhibit Swap RB":{'dbData':telemetryDictionary['Inhibit Swap Radio B'],'fname':'Inhibit Swap Radio B'}}}},
      { id: 'Spoc', type: 'exxbox',parentNode:'CDH', position:{x:975, y:725}, data:{name:'Spoc',oriHeight:'550px', exWidth:'520px', value:{"Uptime":{'dbData':telemetryDictionary['Spoc Uptime'],'fname':''},"Time CSAC TOD":{'dbData':telemetryDictionary['Time CSAC TOD'],'fname':''},"Beacon Mode":{'dbData':telemetryDictionary['Spoc Beacon Mode'],'fname':''},"Monitor Trp":{'dbData':telemetryDictionary['spoc monitor tripped'],'fname':''},"Monitor State":{'dbData':telemetryDictionary['Spoc Monitor State'],'fname':''},"Swap Inhibit":{'dbData':telemetryDictionary['Spoc Swap Inhibbit'], 'fname':''},"Swap Reason":{'dbData':telemetryDictionary['Spoc SwitchOver Reason'], 'fname':''},"Boot Count":{'dbData':telemetryDictionary['SPOC Boot Cnt'],'fname':'SPOC Boot Cnt'}}}},
      
      { id: 'PlimFlash', type: 'exxbox',parentNode:'CDH', position:{x:1800, y:50}, data:{name:'Plim Flash', exWidth:'700px', value:{"Bossa Read Fail Count":{'dbData':telemetryDictionary['Plim Bossa Read Fail Count'],'fname':''},"Bossa Write Fail Count":{'dbData':telemetryDictionary['Plim Bossa Write Fail Count'],'fname':''},"Bossa Read Succ Count":{'dbData':telemetryDictionary['Plim Bossa Read Succ Count'],'fname':''},"Bossa Write Succ Count":{'dbData':telemetryDictionary['Plim Bossa Write Succ Count'],'fname':''},"Samba B Flash Erased":{'dbData':telemetryDictionary['Plim Samba B Flash Erased'],'fname':''},"Samba B Flash Locked":{'dbData':telemetryDictionary['Plim Samba B Flash Locked'],'fname':''},"Samba B Flash Verified":{'dbData':telemetryDictionary['Plim Samba B Flash Verified'],'fname':''},"Samba B Flash Written":{'dbData':telemetryDictionary['Plim Samba B Flash Written'],'fname':''},"Samba B Running":{'dbData':telemetryDictionary['Plim Samba B Running'],'fname':''},"Samba I Byte Err":{'dbData':telemetryDictionary['Plim Samba I Byte Err'],'fname':''}}}},
      { id: 'Plim', type: 'exxbox',parentNode:'CDH', position:{x:1225, y:50}, data:{name:'Plim', exWidth:'520px', value:{"Uptime":{'dbData':telemetryDictionary['Plim Uptime'],'fname':''},"Boot Index":{'dbData':telemetryDictionary['Plim Boot Index'],'fname':''},"Boot Count":{'dbData':telemetryDictionary['Plim Boot Count'],'fname':''},"Boot Chip":{'dbData':telemetryDictionary['Plim Boot Chip'],'fname':''},"Reset Reason":{'dbData':telemetryDictionary['Plim Reset Reason'],'fname':''},"Cpu Temp":{'dbData':telemetryDictionary['Plim Cpu Temp'],'fname':''},"Cpu Usage":{'dbData':telemetryDictionary['Plim Cpu Usage'],'fname':''},"Cpu Usage Peak":{'dbData':telemetryDictionary['Plim Cpu Usage Peak'],'fname':''}}}},
      { id: 'RCS', type: 'exxbox',parentNode:'CDH', position:{x:50, y:50}, data:{name:'RCS', exWidth:'550px', value:{"Trk Disable Cnt":{'dbData':telemetryDictionary['RCS Tracker Disable Counter'],'fname':'Tracker Disable Counter'},"Trk State":{'dbData':telemetryDictionary['RCS Tracker State'],'fname':''},"Threshold A En":{'dbData':telemetryDictionary['RCS Threshold A Enabled'],'fname':''},"Threshold A Trp":{'dbData':telemetryDictionary['RCS Threshold A Tripped'],'fname':''},"Threshold B En":{'dbData':telemetryDictionary['RCS Threshold B Enabled'],'fname':''},"Threshold B Trp":{'dbData':telemetryDictionary['RCS Threshold B Tripped'],'fname':''}}}},
      { id: 'PIU', type: 'exxbox',parentNode:'CDH', position:{x:650, y:50}, data:{name:'PIU', exWidth:'525px', value:{"Uptime":{'dbData':telemetryDictionary['PIU Uptime'],'fname':''},"Boot Index":{'dbData':telemetryDictionary['PIU Boot Index'],'fname':''},"Boot Count":{'dbData':telemetryDictionary['PIU Boot Count'],'fname':''},"Boot Chip":{'dbData':telemetryDictionary['PIU Boot Chip'],'fname':''},"Reset Reason":{'dbData':telemetryDictionary['PIU Reset Reason'],'fname':''},"Cpu Temp":{'dbData':telemetryDictionary['PIU Cpu Temp'],'fname':''},"Cpu Usage":{'dbData':telemetryDictionary['PIU Cpu Usage'],'fname':''},"Cpu Usage Peak":{'dbData':telemetryDictionary['PIU Cpu Usage Peak'],'fname':''}}}},
      
      { id: 'InfoBox', type: 'vmodeBox',parentNode:'', position:{x:150, y:-60}, data:{name:'', VMode:telemetryDictionary['Vehicle Mode Agg'], playBackValue: {time: pbTimeRef.current, loop: pbLoopRef.current}}},

    ]}

    useEffect(() => {
      if (!guiValues.current.pbisInPlayBack) return;
    
      let isCancelled = false;
    
      const processData = async () => {
        // Initialize an empty object to store telemetry data samples
        let aggregatedData: TelemetryDictionary = {};
        const totalLoops = Object.keys(influxData).length;
    
        for (let i = 0; i < totalLoops; i++) {
          // Check if the process should stop
          if (!guiValues.current.pbisInPlayBack || isCancelled) {
            break;
          }

          const key = Object.keys(influxData)[i];
          const selectedData = influxData[key];
            pbTimeRef.current = key;
            const newValue = (i + 1)/totalLoops;
            pbLoopRef.current = newValue;

    
          // Loop through each item in selectedData and update the aggregatedData object
          selectedData.forEach(item => {
            // If the key already exists, update the telemetry value
            if (aggregatedData[item.name]) {
              if (item.cnvValue !== '') {
                aggregatedData[item.name].telemetry = item.cnvValue;
              }
            } else {
              aggregatedData[item.name] = {
                telemetry: item.cnvValue,
                mne: item.mne,
                limit: "",
                unit: "",
                spacecraft: "",
                live: "rgb(68, 169, 241)",
              };
            }
          });
    
          // Create nodes from the aggregatedData object
          const newNodes = createNodes(aggregatedData);
          setNodes(newNodes);
    
          // Log the current loop progress
          //console.log(`${i + 1}/${totalLoops}`);
    
          // Wait for 2 seconds before processing the next item
          await new Promise(resolve => setTimeout(resolve, 2000));
        }
        guiValues.current.pbstopPlay()
        //console.log('Playback completed');
      };
    
      processData();
    
      // Cleanup function to cancel the loop if pbisInPlayBack changes
      return () => {
        isCancelled = true;
      };
    }, [influxData, guiValues.current.pbisInPlayBack]);

    useEffect(() => {
      //console.log('Playback started asfafa', guiValues.pbisInPlayBack);
      if (guiValues.current.pbisInPlayBack) {
        return;
      }
      const telemetryDictionary1 = createTelemetryDictionary();
    
      const newNodes = createNodes(telemetryDictionary1); // Function to create nodes from telemetryDictionary
      setNodes(newNodes);
    }, [dbData, createTelemetryDictionary]);

  enum MarkerType {
    Arrow = 'arrow',
    ArrowClosed = 'arrowclosed',
  }

  const arrow = {type: MarkerType.ArrowClosed, color: 'white', orient: 'auto-start-reverse', width: 15, height: 15};
  
  const initialEdges = [
    {id: 'edge1', source: 'SolarArrsadfaay',sourceHandle: 'source-left', target: 'BCR',targetHandle:'target-right', type:'customEdge',style: { stroke: 'white', strokeWidth:5}, markerEnd: arrow},

 ]; 

  const proOptions = { hideAttribution: true };

  return (
    <ReactFlowProvider>
      <div className={reactAppWrapper} style={{ height: height, width: width }}>
          <ReactFlow          
          edges={initialEdges}
          onInit={onInit}
          edgeTypes={edgeTypes}
          nodes={nodes}
          nodeTypes={nodeTypes}
          proOptions={proOptions}
          //defaultViewport={{ x: option.X, y: option.Y, zoom: option.Zoom}}
          minZoom={0.1}
          panOnDrag={true}
          zoomOnScroll={true}
          zoomOnPinch={true} 
          zoomOnDoubleClick={true}
          fitView={true}
          />
        </div>
  
    </ReactFlowProvider>
  );

}

export default App;
