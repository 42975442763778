import React, { useEffect, useRef, useState } from 'react';
import { PanelProps, DataSourceApi } from '@grafana/data';
import { PanelDataErrorView, getDataSourceSrv } from '@grafana/runtime';
import { useStyles2 } from '@grafana/ui';
import {SimpleOptions } from 'types';
import { decode } from '@msgpack/msgpack';
import { TimeRangeContext } from './TimeRangeContext';
import * as dat from 'lil-gui';
import moment from 'moment-timezone';
import { css, cx } from '@emotion/css';
import { DataObject, ProcessedData, lookUpName, nameToMne, Field } from './utils';
import AppNew from './AppNew';

interface Props extends PanelProps<SimpleOptions> {}

const getStyles = () => {
  return {
    wrapper: css`
      fontFamily: Open Sans;
      position: relative;
    `,
    svg: css`
      position: absolute;
      top: 0;
      left: 0;
    `,
    textBox: css`
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 10px;
    `,
  };
};

export const SimplePanel: React.FC<Props> = ({ options, data, width, height, fieldConfig, id }) => {

  const styles = useStyles2(getStyles);

  const exampleFields: Field[] = [
    { name: "", type: "", values: [], labels: {} }
  ];

  const selectedData = data.series[0] ? data.series[0].fields : exampleFields;
  const influxCuurentDelay = data.series[1] ? data.series[1].fields : exampleFields;

  const oneWayLightTimeField = influxCuurentDelay.find(field => field.name === "One-way Light Time [s]");

  const oneWayLightTimeFieldValues = oneWayLightTimeField && oneWayLightTimeField.values ? oneWayLightTimeField.values[0] : 0;
  
  const sqlQuery = data.series[0] ? data.series[0].meta?.executedQueryString : "";


  let source = "";

  if (sqlQuery) {
    const regex = new RegExp(`facility\\s*=\\s*'(.*?)'`, 'i');
    const match = regex.exec(sqlQuery);
    // Use 'match' here. Remember that 'match' could be null if no match is found.

    if (match) {
      source = match[1]
    }
    
  }

  const test = 'iqhmYWNpbGl0eahlc2NiX2ludKRzY2lkAKRhcGlkzQg0pHZjaWQApXRfcGt0y0HZkZcHAZZCqHRfaW5zZXJ0y0HZkZcHB04Wo21uZb1lcHNfc3BvY19kaW9fcGxpbV9tYWluX3B3cl9lbqNyYXcAo2NudqNPZmapaXNfcHNldWRvwg=='
  const binaryString = window.atob(test);

  // Convert binary string to Uint8Array
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  // Decode the MessagePack data
  const decodedObject = decode(bytes);

  //console.log('o',decodedObject); // Outputs the original object

  function processData(dataArray: DataObject[]): ProcessedData {
    const result: ProcessedData = {};
  
    dataArray.forEach(dataObject => {
      const timeField = dataObject.fields.find(field => field.name.toLowerCase() === "_time");
      const cnvValueField = dataObject.fields.find(field => field.name.toLowerCase() === "cnv_value");
      const tpktField = dataObject.fields.find(field => field.name.toLowerCase() === "t_pkt");
      const tInsertField = dataObject.fields.find(field => field.name.toLowerCase() === "t_insert");

      if (timeField && cnvValueField && tpktField && tInsertField) {

        timeField.values.forEach((timeValue, index) => {
          const cnvValue =  cnvValueField.values[index] === null ? "" : cnvValueField.values[index].toFixed(2);
  
          const mne = cnvValueField && cnvValueField.labels ? cnvValueField.labels.mne : "";
  
          if (!result[timeValue]) {
            result[timeValue] = [];
          }

          const name = lookUpName(mne);
  
          result[timeValue].push({ cnvValue, mne, name });
        });
      }
    });
  
    return result;
  }

  const [processedData, setProcessedData] = useState<ProcessedData>({});

  const fetchData = async (query: string, dataSourceName: string) => {
    // Example query incorporating a variable
    const dataSource: DataSourceApi = await getDataSourceSrv().get(dataSourceName);

        // Execute the query and wait for the promise to resolve
        //@ts-ignore
      const result = await dataSource.query({ targets: [{ query: query, refId: 'comQ' }] }).toPromise();

      return result;

  };

  const playBlackFuunction = () => {

    console.log('playback');

    if (guiValues.current.pbisInPlayBack){
      alert('Playback is already in progress. Please stop the current playback before starting a new one.');
      return;
    }

    guiValues.current.pbisInPlayBack = true;

    const mneList = Object.values(nameToMne);

    // const locations = ["apid_0x124.mne.erawi", "apid_0x124.mne.irawi", "apid_0x124.mne.erawv", "apid_0x124.mne.irawv", "apid_0x10e.mne.imonref","apid_0x119.mne.emag_pwr_on","apid_0x10e.mne.mag_p15i","apid_0x10e.mne.mag_n15i", "apid_0x10e.mne.mag_p3_3i","apid_0x119.mne.p15v","apid_0x119.mne.n15v","apid_0x119.mne.p3_3v","apid_0x119.mne.p2_5v","apid_0x119.mne.n8_3v","apid_0x119.mne.p5v",
    // "state_spinand0_last_cmd_vehicle_mode_agg","apid_0x119.mne.sensor_temp","apid_0x119.mne.pcb_temp","apid_0x112.mne.elp_pwr_on", "apid_0x10e.mne.elp_n15i","apid_0x10e.mne.elp_p15i","apid_0x10e.mne.elp_p3_3i","apid_0x112.mne.p15v","apid_0x112.mne.n15v","apid_0x112.mne.p5v","apid_0x112.mne.p3_3v","apid_0x112.mne.temp_tl_pip","apid_0x112.mne.temp_tr_adc","apid_0x112.mne.temp_ll_mnlp",
    // "apid_0x124.mne.eesa_pwr_on","apid_0x10e.mne.eesa_n15i", "apid_0x10e.mne.eesa_p15i", "apid_0x10e.mne.eesa_p3_3i", "apid_0x10e.mne.eesa_28i", "apid_0x124.mne.ianalt", "apid_0x124.mne.eanalt", "apid_0x124.mne.eanodet", "apid_0x124.mne.digitalt","apid_0x10e.mne.vmon28","apid_0x10e.mne.vmon15","apid_0x10e.mne.vmon_3_3","apid_0x10e.mne.vmonm_15","apid_0x10e.mne.vmoncore","apid_0x10e.mne.icbt",
    // "eps_plim_dio_act_pri_arm_en","eps_plim_dio_act_pri_fire_on","eps_plim_dio_act_sec_arm_en","eps_plim_dio_act_sec_fire_on","apid_0x10f.mne.gnd_cmd_cnt", "apid_0x10f.mne.sc_cmd_cnt", "apid_0x104.mne.crc_errors", "apid_0x119.mne.cmd_accept_cnt", "apid_0x119.mne.cmd_reject_cnt", "apid_0x141.mne.cmds_received", "apid_0x109.mne.board_id","apid_0x109.mne.fsw_major", "apid_0x109.mne.fsw_minor","apid_0x109.mne.fsw_patch", 
    // "apid_0x119.mne.pps_mode", "apid_0x116.mne.missed_sc_pps", "apid_0x116.mne.irregular_sc_pps_cnt", "apid_0x116.mne.sc_pps_delta", "apid_0x119.mne.range_mode", "tcs_thermostats_eidpu_htr_state", "tcs_thermostats_eesa_htr_state","tcs_thermostats_e_mag_htr_state", "EPS_PLIM_POWER_RAIL_SENSE_28V_ISO_I_agg", "cdh_plim_uptime_ms", "tcs_supervisor_boom_actuator_t_raw_agg", "EPS_SPOC_DIO_PLIM_MAIN_PWR_EN_agg","eps_spoc_power_plim_i","eps_plim_dio_m_pl_pri_pwr_en",
    // "apid_0x122.mne.attn_mode_change_cnt","apid_0x122.mne.attn_state_mismatch_cnt","apid_0x141.mne.act_status1","EPS_PLIM_DIO_M_PL_PRI_PWR_EN","EPS_PLIM_DIO_M_PL_SEC_PWR_EN","eps_plim_dio_m_pl_pri_pwr_on","eps_plim_dio_m_pl_sec_pwr_on","CDH_SPOC_UPTIME_agg","EPS_SUPERVISOR_POWER_RAIL_SPOCA_VTLM_agg","EPS_SUPERVISOR_POWER_RAIL_SPOCB_VTLM_agg", "EPS_SUPERVISOR_POWER_RAIL_SPOCA_CURR_TLM_agg","EPS_SUPERVISOR_POWER_RAIL_SPOCB_CURR_TLM_agg", "eps_supervisor_dio_spoc_select","eps_plim_dio_cam_pwr_on", 
    // "eps_plim_power_rail_sense_cam_i", "eps_plim_power_rail_sense_cam_v", "tcs_spoc_cptn_cam_t_raw_agg", "apid_0x141.mne.hv_status", "apid_0x124.mne.iaccelv", "apid_0x124.mne.ihemiv", "apid_0x124.mne.idef1v","apid_0x124.mne.idef2v", "apid_0x124.mne.ispoilerv", "apid_0x124.mne.imcpv", "apid_0x124.mne.ehemiv", "apid_0x124.mne.edef1v","apid_0x124.mne.edef2v","apid_0x124.mne.espoilerv","apid_0x124.mne.emcpv"];

    const mneFilter = mneList.map(mne => `r.mne == "${mne.toLowerCase()}"`).join(' or ');

    // const start = new Date(guiValues.startDateTime).toISOString();
    // const end = new Date(guiValues.endDateTime).toISOString();
    const start = moment.tz(new Date(guiValues.current.startDateTime), 'America/Los_Angeles').utc().format();
    const end = moment.tz(new Date(guiValues.current.endDateTime), 'America/Los_Angeles').utc().format();

    const aggTime = guiValues.current.pbaggTime;
    const aggFn = guiValues.current.pbaggFn.fn;

    //console.log('d', start, end, aggTime, aggFn, locationFilter, source)
    console.log('source', source);
    
    let newSource;
    if (source === "escg_flight") {
      newSource = 'escg_flight';
    }else if (source === "escb_flight") {
      newSource = 'escb_flight';
    }else {
      newSource = 'esc_flatsat';
    }


    const exQuery = `from(bucket: "telemetry")
    |> range(start: ${start}, stop: ${end})
    |> filter(fn: (r) =>
        r._measurement == "telemetry" and  
        r.facility == "${newSource}" and
        (${mneFilter}) 
    )
    |> filter(fn: (r) =>
        r._field == "cnv_value" or r._field == "t_pkt" or r._field == "t_insert"
    )
    |> aggregateWindow(every: ${aggTime}, fn: ${aggFn})
    |> pivot(rowKey: ["_time"], columnKey: ["_field"], valueColumn: "_value")
  `;

  console.log('exQuery', exQuery);

    fetchData(exQuery, 'INFLUX').then(result => {
      if (result && result.data) { 
        console.log('result', result);
        if (result.state === 'Error') {
          alert(`Error: ${result.error.message} (Status: ${result.error.status})`);
        }
        const queryData = result.data; 
        const processInfluxData = processData(queryData);
        setProcessedData(processInfluxData);
      }
    });
  } 

  const stopPlayBack = () => {
    guiValues.current['pbisInPlayBack'] = false;
  }

  const guiValues = useRef({
    aggTime: '1s',
    aggFn: { fn: 'last' },
    Agg: false,
    Last: false,
    lastCount: 50,

    startDateTime: '2024-08-29 21:05:00',
    endDateTime: '2024-08-29 21:08:00',
    pbaggTime: '1s',
    pbaggFn: { fn: 'mean' },
    pbplayBack: playBlackFuunction,
    pbstopPlay: stopPlayBack,
    pbisInPlayBack: false,
  });

  // lil-gui configuration
  const guiContainerRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (guiContainerRef.current) {
      const gui = new dat.GUI({ container: guiContainerRef.current });
      gui.close();
  
      //gui.add(guiValues, 'screenSpacePanning').name('Space Panning');
  
      const f1 = gui.addFolder('Query');
     //f1.add(guiValues, 'startDateTime').name('Start Date Time').listen();
      //f1.add(guiValues, 'endDateTime').name('End Date Time').listen();
  
      const aggController = f1.add(guiValues.current, 'Agg').name('Agg');
      const aggTimeController = f1.add(guiValues.current, 'aggTime').name('Agg Time');
      const aggFnController = f1.add(guiValues.current.aggFn, 'fn', ['last', 'mean', 'sum', 'count']).name('Agg Fn');
      aggFnController.disable();
      aggTimeController.disable();
   
      const lastController = f1.add(guiValues.current, 'Last').name('Last');
      const lastCountController = f1.add(guiValues.current, 'lastCount', 1, 1000, 1).name('Last Count');
      lastCountController.disable();
  
      aggController.onChange((value: boolean) => {
        if (value) {
          aggTimeController.enable();
          aggFnController.enable();
        } else {
          aggTimeController.disable();
          aggFnController.disable();
        }
      });
  
      lastController.onChange((value: boolean) => {
        if (value) {
          lastCountController.enable();
        } else {
          lastCountController.disable();
        }
      });

      const f2 = gui.addFolder('Playback');
      f2.add( guiValues.current, 'startDateTime').name('Start Date Time');
      f2.add( guiValues.current, 'endDateTime').name('End Date Time');
      f2.add( guiValues.current, 'pbaggTime').name('Agg Time');
      f2.add(guiValues.current.pbaggFn, 'fn', ['last', 'mean', 'sum', 'count']).name('Agg Fn');
      //f1.add( guiValues, 'loadingPercent', 0,100, 1).name('Loading %').disable();
      f2.add( guiValues.current, 'pbplayBack').name('Playback');
      f2.add( guiValues.current, 'pbstopPlay').name('Stop Playback');

      f1.close();
      f2.close();
      if (!guiValues.current.pbisInPlayBack) {
        setProcessedData({});
      }
      
    }
  }, [guiValues]);

    // Extract the time range
    const timeRange = {
      from: data.timeRange.from,
      to: data.timeRange.to
    };
    
  if (data.series.length === 0) {
    return <PanelDataErrorView fieldConfig={fieldConfig} panelId={id} data={data} needsStringField />;
  }

  return (
    <div       
    className={cx(
      styles.wrapper,
      css`
        width: ${width}px;
        height: ${height}px;

        .lil-gui.root{
          --background-color: #d29d00;
          --font-size: 11px;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1000;
          --background-color: #002b36;
          --text-color: #b2c2c2;
          --title-background-color: #001f27;
          --title-text-color: #b2c2c2;
          --widget-color: #094e5f;
          --hover-color: #0a6277;
          --focus-color: #0b6c84;
          --number-color: #2aa0f2;
          --string-color: #97ad00;
          --folder-indent: 100px;
          --widget-border-radius: 0px;
        }
      `
    )}>
    <TimeRangeContext.Provider value={{ timeRange, guiValues, oneWayLightTimeFieldValues }}>
    <div ref={guiContainerRef} />
        {/* {state[0].active && <App dbData={selectedData} source={source} width={width} height={height} options={options}></App>} */}
        {/*state[5].active && <AlertPage></AlertPage>*/}
        <AppNew dbData={selectedData} width={width} height={height} source={source} options={options} influxData={processedData}></AppNew>

        </TimeRangeContext.Provider>
    </div>
  );
};
